export type Headers = {}

export interface Authenticator {

    addAuthenticationHeaders(headers?: Headers): Promise<Headers>

    handleForbidden(message: string): Promise<any>

    handleUnauthorized(message: string): Promise<any>
}

export class SsoIdAuthenticator implements Authenticator {

    constructor(protected ssoId: string,
                protected authErrorHandler: () => Promise<any>,
                protected forbiddenErrorHandler?: () => Promise<any>) {
    }

    async addAuthenticationHeaders(headers: Headers = {} as Headers): Promise<Headers> {
        if (this.ssoId) {
            headers['X-compeople-ssoid'] = this.ssoId;
        }
        return headers;
    }

    async handleForbidden(message: string) {
        await this.handleForbiddenError(message);
    }

    async handleUnauthorized(message: string) {
        await this.handleAuthError(message);
    }

    private async handleAuthError(message: string) {
        console.error('Auth 401:', message);
        if (this.authErrorHandler) {
            await this.authErrorHandler();
        }
    }
    private async handleForbiddenError(message: string) {
        console.error('Forbidden 403:', message);
        if (this.forbiddenErrorHandler) {
            await this.forbiddenErrorHandler();
        }
    }

}
