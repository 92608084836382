import {ProtocolEntry} from '../models';
import {RestClient} from './util/rest-client';

export class ProtocolService {
  constructor(private restClient: RestClient) {
  }

  async all(): Promise<ProtocolEntry[]> {
    return await this.restClient.json<ProtocolEntry[]>(
      '/rest/v1/protokolle'
    );
  }

  async byUuid(uuid: string): Promise<ProtocolEntry[]> {
    return await this.restClient.json<ProtocolEntry[]>(`/rest/v1/protokolle/assistent/${uuid}`);
  }

  async byAssistentUuid(assistentUuid: string): Promise<ProtocolEntry[]> {
    return await this.restClient.json<ProtocolEntry[]>(`/rest/v1/protokolle/assistent/${assistentUuid}`);
  }
}


