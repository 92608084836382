import {
    ApplicationInsights,
    IEventTelemetry,
    IExceptionTelemetry,
    ITraceTelemetry,
    SeverityLevel
} from '@microsoft/applicationinsights-web';
import {EnvironmentService} from './environment-service';
import {ICustomProperties} from '@microsoft/applicationinsights-core-js';
import { ServerError } from '../models';

export const APP_INSIGHTS_KEY: string = 'appInsightsKey';

const APP_INSIGHTS_NOT_CONFIGURED: string = 'appInsights not configured';

export class AzureLogging {

    public static async initializeFromEnvironmentConfiguration(environmentService: EnvironmentService): Promise<AzureLogging> {
        let key = await environmentService.getOptionalConfig<string>(APP_INSIGHTS_KEY);
        return new AzureLogging(key);
    }


    private appInsights: ApplicationInsights;

    private constructor(appInsightsKey: string) {
        if (!appInsightsKey) {
            console.warn(APP_INSIGHTS_NOT_CONFIGURED);
        } else {
            this.appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: appInsightsKey,
                    disableFetchTracking: false,
                    enableAjaxErrorStatusText: true,
                    enableAutoRouteTracking: true,
                    sessionRenewalMs: 180000
                }
            });
            this.appInsights.loadAppInsights();
            this.appInsights.trackPageView();
        }
    }

    public trackException(error: Error | ServerError) {
        if (!this.appInsights) {
            console.error(APP_INSIGHTS_NOT_CONFIGURED, error);
        } else {
            this.appInsights.trackException({
                exception: error,
            } as IExceptionTelemetry);
        }
    }

    public trackEvent(event: IEventTelemetry, customProperties?: ICustomProperties) {
        if (!this.appInsights) {
            console.warn(APP_INSIGHTS_NOT_CONFIGURED);
        } else {
            this.appInsights.trackEvent(event, customProperties);
        }
    }

    public trackTrace(message: string, severity: SeverityLevel, customProperties?: ICustomProperties) {
        if (!this.appInsights) {
            console.warn(APP_INSIGHTS_NOT_CONFIGURED, message);
        } else {
            this.appInsights.trackTrace(
                {message, severity} as ITraceTelemetry,
                customProperties
            );
        }
    }

    public infoTrace(message: string, customProperties?: ICustomProperties) {
        this.trackTrace(message, SeverityLevel.Information, customProperties);
    }

}
