import {ASSISTENT_VERTRAG_FRONTEND_BUILD_VERSION} from '../version';
import {getOriginOfScript} from '../file-loader';

const ENVIRONMENT_KEY: string = 'environment';
const BACKEND_KEY: string = 'backend';


export class EnvironmentService {

    private configData: any;

    async version(): Promise<string> {
        if (ASSISTENT_VERTRAG_FRONTEND_BUILD_VERSION.includes('$$$')) {
            return 'Nicht definiert';
        }
        return ASSISTENT_VERTRAG_FRONTEND_BUILD_VERSION;
    }

    async meineDvagUrl(): Promise<string> {
        const environment = await this.environment();

        switch(environment) {
            case AppEnvironment.Produktion:
                return 'https://meine.dvag';
            case AppEnvironment.Abnahme:
              return 'https://abnahme.meine.dvag';
            case AppEnvironment.Entwicklung:
                return 'https://entwicklung.meine.dvag';
            case AppEnvironment.Integration:
                return 'https://integration.meine.dvag';
            default:
                return 'https://meine.dvag';
        }
    }

    async benutzerkontoUrl(): Promise<string> {
        const environment = await this.environment();
        switch (environment) {
            case AppEnvironment.Entwicklung:
                return 'https://benutzerkonto.entwicklung.k8s.dvag.net';
            case AppEnvironment.Integration:
                return'https://benutzerkonto.integration.k8s.dvag.net';
            case AppEnvironment.Abnahme:
                return 'https://benutzerkonto.abnahme.dvag';
            case AppEnvironment.Produktion:
                return 'https://benutzerkonto.dvag';
            default:
                return 'https://benutzerkonto.dvag';
        }

    }

    async environment(): Promise<AppEnvironment> {
        const environmentString = await this.getConfig<string>(ENVIRONMENT_KEY);
        const environment = AppEnvironment[environmentString];
        if (environment != null) {
            localStorage.setItem(STORAGE_ENVIRONMENT, environment);
            console.log('Using environment', environment);
            return environment;
        }
        throw Error('Autodetection of environment failed.');
    }

    public async isLokaleEntwicklung(): Promise<boolean> {
        const env = await this.environment();
        return env === AppEnvironment.LokaleEntwicklung;
    }

    async backendUrl(): Promise<string> {
        return await this.getConfig<string>(BACKEND_KEY);
    }


    public async getConfig<T>(key: string): Promise<T> {
        return await this.getConfigInternal<T>(key, false);
    }

    public async getOptionalConfig<T>(key: string): Promise<T> {
        return await this.getConfigInternal<T>(key, true);
    }

    private async getConfigInternal<T>(key: string, optional:boolean): Promise<T> {
        let config = await this.getConfigJson();
        const value: T = config[key];
        if (!value && !optional) {
            throw Error(`No value found for key '${key}' in config ${JSON.stringify(config)}`);
        }
        return value;
    }

    private async getConfigJson(): Promise<any> {
        if (this.configData) {
            return this.configData;
        }
        try {
            const origin = getOriginOfScript();
            const response = await fetch(origin + '/assets/assistent/config/config.json');
            this.configData = await response.json();
            return this.configData;
        } catch (e) {
            throw Error('config.json not found');
        }
    }
}

export enum AppEnvironment {
    LokaleEntwicklung = 'Lokale Entwicklung',
    Entwicklung = 'Entwicklung',
    Integration = 'Integration',
    Abnahme = 'Abnahme',
    Produktion = 'Produktion'
}

export const STORAGE_ENVIRONMENT = 'environment';
