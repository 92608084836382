import {
  AssistentServerModelWithZuordnung,
  AssistentModel,
  AssistentServerModel,
  AssistentModification,
  AssistentResult, SperrStatus, ServerError,
} from '../models';
import {RestClient} from './util/rest-client';
import { DateUtils } from '../date-utils';
import { SortDirection } from '@dvag/design-system';

export class AssistentenService {
  constructor(private restClient: RestClient) {
  }

  async all(): Promise<AssistentResult> {
    return await this.restClient.json<AssistentResult>(
      '/rest/v1/assistenten'
    );
  }

  async byPage(page: number, size: number, sort?: string, orderBy?: SortDirection): Promise<AssistentResult> {
    const sortQuery = sort ? `&sort=${sort},${orderBy}` : '';
    return await this.restClient.json<AssistentResult>(
      `/rest/v1/assistenten?page=${page}&size=${size}${sortQuery}`
    );
  }

  async add(assistent: AssistentModel): Promise<AssistentServerModel> {
    return await this.restClient.json<AssistentServerModel>(
      '/rest/v1/assistenten'
      , 'POST', assistent);
  }

  async byUuid(uuid: string): Promise<AssistentServerModel> {
    try {
      return await this.restClient.json<AssistentServerModel>(`/rest/v1/assistenten/${uuid}`);
    } catch (e) {
      if (e instanceof ServerError && (e as ServerError).status === 404) {
        return null;
      } else {
        throw e;
      }
    }
  }

  async zuordnungenByUuid(uuid: string): Promise<AssistentServerModelWithZuordnung[]> {
    return await this.restClient.json<AssistentServerModelWithZuordnung[]>(`/rest/v1/assistenten/${uuid}/zuordnungen`);
  }

  async duplicateCheckByNachnameAndGeburtsdatum(nachname: string, geburtsdatum: string): Promise<AssistentServerModel[]> {
    const uriNachname = encodeURIComponent(nachname);
    const uriGeburtsdatum = encodeURIComponent(geburtsdatum.substring(0, 'YYYY-MM-DD'.length));
    return await this.restClient.json<AssistentServerModel[]>(`/rest/v1/assistenten/duplikate?nachname=${uriNachname}&geburtsdatum=${uriGeburtsdatum}`);
  }

  async duplicateCheckByAssistentenNummer(assistentenNummer: string): Promise<AssistentServerModel[]> {
    const assistentenNummerWithLeadingZeros = assistentenNummer.padStart(7, '0');
    const uriAssistentenNummer = encodeURIComponent(assistentenNummerWithLeadingZeros);
    return await this.restClient.json<AssistentServerModel[]>(`/rest/v1/assistenten/duplikate/by-asssistenten-nummer/${uriAssistentenNummer}`);
  }

  async update(modification: AssistentModification): Promise<AssistentServerModel> {
    const {file, stammdaten, kontaktDaten} = modification;
    const formData = new FormData();
    if (file) formData.append('pdf', file);
    const data = {stammdaten, kontaktDaten};
    formData.append('data', new Blob([JSON.stringify(data)], {type: 'application/json'}));
    return await this.restClient.multiPart<AssistentServerModel>(`/rest/v1/assistenten/${modification.base.uuid}`, 'PATCH', formData);
  }

  async bySearchTerm(searchTerm: string, page: number, size: number, sort?: string, orderBy?: SortDirection): Promise<AssistentResult> {
    const uriSearchTerm = encodeURIComponent(searchTerm);
    const sortQuery = sort ? `&sort=${sort},${orderBy}` : '';
    return await this.restClient.json<AssistentResult>(`/rest/v1/assistenten?searchTerm=${uriSearchTerm}&page=${page}&size=${size}${sortQuery}`);
  }

  async lock(uuid: string, sperrStatusDTO: SperrStatus) {
    return await this.restClient.json(
      `/rest/v1/assistenten/${uuid}/sperr-status`
      , 'PUT',
      {
        sperrStatusDTO,
        sperrDatum: DateUtils.dateToServerDateWithTime(new Date())
      });
  }
}


