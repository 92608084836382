import {Authenticator} from '../authentication/authenticator';
import { ProblemDetail, ServerError } from '../../models';

export class RestClient {
    constructor(
      private backendUrl: string,
      private authenticator: Authenticator,
    ) {
    }

    async multiPart<T>(url: string, method: RestMethod = 'GET', body: any = null): Promise<T> {
        const headers = await this.authenticator.addAuthenticationHeaders({
            'Accept': 'application/json',
        });
        const response = await fetch(this.backendUrl + url, {
            method: method,
            body,
            headers,
        });
        await this.handleError(response);
        try {
            return await response.json() as T;
        } catch (error) {
            return null;
        }
    }

    async json<T>(url: string, method: RestMethod = 'GET', data: any = null): Promise<T> {
        const hasJsonBody = method == 'POST' || method == 'PATCH' || method == 'PUT';
        const headers = await this.authenticator.addAuthenticationHeaders({'Content-Type': hasJsonBody ? 'application/json' : undefined});
        const response = await fetch(this.backendUrl + url, {
            method: method,
            body: hasJsonBody && data ? JSON.stringify(data) : undefined,
            headers
        });

        await this.handleError(response);
        try {
            return await response.json() as T;
        } catch (error) {
            console.log('ERROR', error);
            return null;
        }
    }

    async blob(url: string): Promise<Blob> {
        const headers = await this.authenticator.addAuthenticationHeaders();
        const response = await fetch(this.backendUrl + url, {
            headers
        });
        await this.handleError(response);
        return await response.blob();
    }

    async blobWithData(url: string): Promise<{ contentType: string, filename: string, file: Blob }> {
        const headers = await this.authenticator.addAuthenticationHeaders();
        const response = await fetch(this.backendUrl + url, {
            headers
        });
        await this.handleError(response);

        const contentDisposition = response.headers.get('content-disposition');
        const contentType = response.headers.get('content-type');

        // response.headers.forEach(it => {console.log('HEADER', it)})
        // console.log('RESPONSE HEADERS', contentDisposition);

        let filename: string;
        try {
            const matchContentDisposition = contentDisposition ? contentDisposition.match(/filename=(.+)/) : null;
            filename = matchContentDisposition?.length > 1 ? matchContentDisposition[1] : null;
        } catch (err) {
            filename = null;
        }
        const blob = await response.blob();

        return {
            contentType,
            filename,
            file: blob,
        };
    }

    private async handleError(response: Response): Promise<void> {
        if (!response.status.toString().startsWith('2')) {
            let serverErrorMessage = null;
            let errorCode = null;
            try {
                const json = await response.json();
                if (this.isProblemDetailResponse(json)) {
                    serverErrorMessage = (json as ProblemDetail).detail;
                    errorCode = (json as ProblemDetail).status;
                }

            } catch (e) {
            }
            const message = serverErrorMessage ? serverErrorMessage : 'Fehler bei der Kommunikation mit dem Server';
            switch (response.status) {
                case 401: {
                    await this.authenticator.handleUnauthorized(message);
                    break;
                }
                case 403: {
                    await this.authenticator.handleForbidden(message);
                    break;
                }
                default: {
                    throw new ServerError(response.status, message, errorCode);
                }
            }
        }
    }

    private isProblemDetailResponse(json: any) {
        return json.status && json.title;
    }
}

export type RestMethod = 'GET' | 'POST' | 'DELETE' | 'PATCH' | 'PUT';
