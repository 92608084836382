import {RestClient} from './util/rest-client';
import {AssistentModel, AssistentServerModelWithZuordnung} from '../models';

export class GvaService {
  constructor(private restClient: RestClient) {
  }

  async add(assistent: AssistentModel, upn: string, vbNummer: string, mitbenutzer: string): Promise<AssistentServerModelWithZuordnung> {
    const mitbenutzerNummer = mitbenutzer ? mitbenutzer : null;
    const data = {
      ...assistent,
      upn,
      vbNummer,
      mitbenutzerNummer
    };
    return await this.restClient.json('/rest/v1/gva', 'POST', data);
  }
}
