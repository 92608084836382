// TODO Review: Nur sinnvoll, wenn Script direkt ins HTML eingebunden, andere Lösung sollte gefunden werden
export const getOriginOfScript = () => {
  const scriptElement: HTMLScriptElement = document.querySelector('script[src*="assistentensystem-frontend.js"]');

  const scriptUrl = scriptElement?.src;

  if (scriptUrl) {
    const url = new URL(scriptUrl);
    return url.origin;
  }
  return '';
};
