import { RestClient } from './util/rest-client';
import { WhitelistEntryResult, WhitelistInfo, WhitelistProtocol } from '../whitelist-model';
import { SortDirection } from '@dvag/design-system';

export class SonderentscheidService {
  constructor(private restClient: RestClient) {
  }

  async bySearchTerm(searchTerm: string, page: number, size: number, sort?: string, orderBy?: SortDirection) {
    const sortQuery = sort ? `&sort=${sort},${orderBy}` : '';
    const uriSearchTerm = encodeURIComponent(searchTerm);
    return await this.restClient.json<WhitelistEntryResult>(`/rest/v1/sb/whitelist?searchTerm=${uriSearchTerm}&page=${page}&size=${size}${sortQuery}`);
  }

  async byPage(page: number, size: number, sort?: string, orderBy?: SortDirection) {
    const sortQuery = sort ? `&sort=${sort},${orderBy}` : '';
    return await this.restClient.json<WhitelistEntryResult>(
      `/rest/v1/sb/whitelist?page=${page}&size=${size}${sortQuery}`
    );
  }

  async protocol(page: number, size: number): Promise<WhitelistProtocol> {
    return await this.restClient.json<WhitelistProtocol>(`/rest/v1/sb/whitelist/protocols?page=${page}&size=${size}`, 'GET');
  }

  async protocolForUser(vbNummer: string, page: number, size: number): Promise<WhitelistProtocol> {
    return await this.restClient.json<WhitelistProtocol>(`/rest/v1/sb/whitelist/protocols?vbNummerAssistent=${vbNummer}&page=${page}&size=${size}`, 'GET');
  }

  async update(whitelistInfo: WhitelistInfo): Promise<void> {
    const formData = new FormData();
    formData.append('data', new Blob([JSON.stringify(whitelistInfo)], {type: 'application/json'}));
    return await this.restClient.multiPart<void>('/rest/v1/sb/whitelist', 'POST', formData);
  }

  async delete(vbNummer: string) {
    await this.restClient.json(
      `/rest/v1/sb/whitelist/${vbNummer}`
      , 'DELETE');
  }
}
