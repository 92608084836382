import {
    DuplicateCheckQueryByAssistentenNummer,
    DuplicateCheckQueryByName
} from '../components/vb-add-assistent/asys-add-assistent-check-for-duplicates/asys-add-assistent-check-for-duplicates';
import { WhitelistState } from './whitelist-model';
import { SortDirection } from '@dvag/design-system';

export interface GvaModel extends AssistentModel {
    gvaVbNummer: string;
    gvaMitbenutzernummer: string;
    gvaUpn: string;
}

export interface AssistentModel extends AssistentKontaktdaten, AssistentNamen, AssistentInfo {
}

export interface AssistentStammdaten extends AssistentNamen, AssistentInfo { }

export interface AssistentNamen {
    vorname: string;
    nachname: string;
}

export interface AssistentInfo {
    anrede: Anrede;
    titel: Titel;
    geburtsdatum: string;
    vbNummerOfAssistent?: string;
    vbNummer?: string;
    vbVorname?: string;
    vbNachname?: string;
    assistentenTyp?: AssistentenTyp;
}

export interface AssistentKontaktdaten {
    privateEmailAdresse: string;
    privateMobilfunknummer: string;
    strasse: string;
    hausnummer: string;
    strasseZusatz: string;
    postleitzahl: string;
    ort: string;
    land: Land;
}

export type Anrede = 'herr' | 'frau';
export type Titel = '' | 'prof' | 'dr';
export type Land = 'de' | 'at' | 'ch';
export enum AssistentenTyp {
    'GVA' = 'GVA',
    'ASSISTENT' = 'Asssistent'
};

export interface AssistentServerModel extends AssistentModel {
    mitbenutzerNummer?: string;
    assistentenNummer?: string;
    uuid?: string;
    upn?: string;
    status?: AssistentStatusModel;
    assistentenTyp?: AssistentenTyp;

    vbNummer?: string;
    vbHasDebt?: boolean;
    vbIsTerminated?: boolean;
    whitelistState?: WhitelistState;

    sperrStatus?: SperrStatus;
    sperrDatum?: string;
}

export interface AssistentServerModelWithZuordnung extends AssistentServerModel {
    zuordnungsStatus: ZuordnungStatusModel;
    vertrag: AssistentVertrag;
    vbAnrede: string;
    vbGesellschaft: string;
    vbNachname: string;
    vbNummer: string;
    vbVorname: string;
    bestaetigtAm: string;
    laeuftAbAm: string;
    bestaetigungsStatus: BestaetigungsStatus;
    isErsteZuordnung?: boolean;
}

export interface AssistentVertrag {
    id: number,
    zuordnungId: number,
    documentId: string,
    vertragDatum: string,
    kuendigungBeschlossenDatum: string,
    kuendigungBenachrichtigungVerschickt: boolean,
    kuendigungDatum: string,
    kuendigungGrund: KuendigungGrund,
    kuendigungNotiz: string,
    kuendigungDurchBenutzer: string
}

export interface AssistentDuplicatesServerModel {
    assistenten: AssistentServerModel[]
}

export interface AssistentStatusModel {
    assistentenStatus: AssistentStatus;
    selectable: boolean;
    editability: EditableStatus;
    visualization: StatusVisualization;
}
export interface StatusVisualization {
    text: string;
    color: StatusColor;
}

export interface ZuordnungStatusModel {
    zuordnungsStatus: ZuordnungStatus;
    visualization: StatusVisualization;
}

export type StatusColor = 'grey' | 'green' | 'yellow' | 'red';
export type ZuordnungStatus = 'angelegt' | 'aktiv' | 'aufgeloest';
export type AssistentStatus = 'angelegt' | 'aktiv' | 'archiviert';
export type BestaetigungsStatus = 'abgelaufen' | 'gueltig' | 'warte';
export type EditableStatus = 'all' | 'contactDataOnly' | 'non';

export interface AssistentSearchFilter {
    searchTerm?: string;
}

export enum AssistentenDuplicateSearchType {
    NAME = 'name',
}

export enum AddAssistentByDuplicate {
    Confirm = 'confirm',
    Edit = 'edit'
}

export interface AssistentModification {
    base: AssistentServerModel;
    stammdaten: AssistentStammdaten;
    kontaktDaten: AssistentKontaktdaten;
    file: File;
}

export interface ProtocolEntry {
    id: bigint;
    assistentennummer: number;
    erstelltVon: string;
    type: ProtocolEntryType;
    erstelltAm: string;
    info: string;
    docId?: string;
}

export type ProtocolEntryType = 'assistentAktiv' | 'zuordnungAktiv' | 'zuordnungAufgeloest' | 'assistentArchiviert' | 'assistentGeloescht' | 'assistentBestaetigt';

export interface AssistentKuendigung {
    kuendigungDatum: string;
    kuendigungGrund: KuendigungGrund;
    kuendigungNotiz: string;
    file?: File;
}

export const kuendigungGrundText = {
    hauptnutzer_ohne_begruendung: 'Kündigung durch den Hauptnutzer / Assistenten',
    hauptnutzer_fehlverhalten_gesellschaft: 'Kündigung durch den Hauptnutzer, Fehlverhalten gegenüber der Gesellschaft',
    gesellschaft: 'Kündigung durch die Gesellschaft',
    gesellschaft_abgelaufen: 'Kündigung durch die Gesellschaft',
    gesellschaft_austritt: 'Kündigung durch die Gesellschaft, Austritt des Hauptnutzers',
    assistent: 'Kündigung durch den Hauptnutzer / Assistenten',
};

export enum KuendigungGrund {
    HAUPTNUTZER = 'hauptnutzer_ohne_begruendung',
    HAUPTNUTZER_FEHLVERHALTEN = 'hauptnutzer_fehlverhalten_gesellschaft',
    GESELLSCHAFT = 'gesellschaft',
    GESELLSCHAFT_ABGELAUFEN = 'gesellschaft_abgelaufen',
    GESELLSCHAFT_AUSTRITT = 'gesellschaft_austritt',
    ASSISTENT = 'assistent'
}

export interface MitbenutzerModel {
    mitbenutzerNummer: string;
}


export interface AssistentResult {
    _embedded: AssistentServerModel[];
    page: Page
}

export interface AssistentRequest {
    page: number;
    size: number;
    sort?: string;
    orderBy?: SortDirection;
}

export interface WhitelistProtocolRequest extends AssistentRequest {
    aspirantId: string;
}

export interface Page {
        empty: boolean;
        first: boolean;
        last: boolean;
        number: number;
        numberOfElements: number;
        pageable: {
            offset: number;
            pageNumber: number;
            pageSize: number;
            paged: boolean;
            sort: {
                sorted: boolean;
                unsorted: boolean;
                empty: boolean;
            }
            empty: boolean;
            sorted: boolean;
            unsorted: boolean;
            unpaged: boolean;
        }
        size: number;
        sort: {
            sorted: boolean;
            unsorted: boolean;
            empty: boolean;
        }
        totalElements: number;
        totalPages: number;
}

export interface BestaetigtAmModel {
    vbNummer: string;
    assistentNummer: string;
    date: string;
    time: string;
    resetErinnerung: boolean;
}

export interface PreparedDuplettensuche {
    searchData: DuplicateCheckQueryByAssistentenNummer & DuplicateCheckQueryByName;
    searchType: AssistentenDuplicateSearchType;
}

export interface SbConfirmGueltigkeit {
    assistent: AssistentServerModelWithZuordnung;
    pdf: File;
}

export interface StatisticModel {
    day: string;
}

export enum SperrStatus {
    LOCKED = 'gesperrt',
    UNLOCKED = 'entsperrt'
}

export interface ChangeSperrstatus {
    assistent: AssistentServerModel;
    status: SperrStatus;
}

export interface ProblemDetail {
    type?: string
    title: string
    status: number
    detail?: string
    instance?: string
    violations?: {
        code?: string;
        detail: string;
        source?: string;
    }[]
}

export class ServerError extends Error {
    constructor(public status: number, public message: string, public errorCode: string) {
        super(message);
    }
}