import {RestClient} from './util/rest-client';

export class BackendService {
    constructor(private restClient: RestClient) {
    }

    async authedUser(): Promise<UserDataInfo> {
        const data = await this.restClient.json<UserDataInfo>(
            '/rest/v1/zob/userdata'
        );
        if (!data) {
            return null;
        }
        data.permissions = data.permissions.filter(it => it !== '');
        return data;
    }

    async backendBuildInformation(): Promise<BackendBuildInformation> {
        return await this.restClient.json('/rest/v1/info/version');
    }

    async requestMerkblatt(): Promise<Blob> {
        return await this.restClient.blob('/rest/v1/documents/merkblatt');
    }

    async loadPdf(docId: string) {
        return await this.restClient.blob(`/rest/v1/documents/${docId}`);
    }

    async statistic(tag) {
        return await this.restClient.blobWithData(`/rest/v1/sb/statistics?day=${tag}`);
    }
}


export interface BackendBuildInformation {
    version: string;
}

export interface UserDataInfo {
    benutzer: UserInfo;
    permissions: string[];
}

export interface UserInfo {
    anrede: string;
    benutzertyp: string;
    geburtsdatum: string;
    gesellschaft: UserGesellschaft;
    name: string;
    titel: string;
    userid: string;
    vorname: string;
}

export enum UserGesellschaft {
    DVAG = 'DVAG',
    DVAG_WIEN = 'DVAG-Wien',
    ALLFINANZ = 'Allfinanz',
    ALLFINANZ_AG = 'Allfinanz AG'
}

export const isFromAustria = (gesellschaft: UserGesellschaft) => {
    if (!gesellschaft) {
        return false;
    }
    return gesellschaft === UserGesellschaft.DVAG_WIEN;
};
