import {
  AssistentKuendigung,
  AssistentServerModelWithZuordnung,
  MitbenutzerModel,
  BestaetigtAmModel,
} from '../models';
import {RestClient} from './util/rest-client';
import {DateUtils} from '../date-utils';

export class VbAssistentZuordnungService {
  constructor(private restClient: RestClient) {
  }

  async mitbenutzer(vbNummer: string): Promise<MitbenutzerModel[]> {
    try {
       return await this.restClient.json<MitbenutzerModel[]>(
       `/rest/v1/vermoegensberater/${vbNummer}/mitbenutzer`
       );
    } catch(e) {
      return [];
    }
  }

  async allByVbNummer(vbNummer: string): Promise<AssistentServerModelWithZuordnung[]> {
    return await this.restClient.json<AssistentServerModelWithZuordnung[]>(
      `/rest/v1/vermoegensberater/${vbNummer}/zuordnungen/assistenten`
    );
  }

  async get(vbNummer: string, uuid: string): Promise<AssistentServerModelWithZuordnung> {
    return await this.restClient.json<AssistentServerModelWithZuordnung>(
      `/rest/v1/vermoegensberater/${vbNummer}/zuordnungen/assistenten/${uuid}`
    );
  }

  async add(vbNummer: string, uuid: string, mitbenutzerNummer: string): Promise<AssistentServerModelWithZuordnung> {
    const data = mitbenutzerNummer ? {mitbenutzerNummer} : null;
    return await this.restClient.json<AssistentServerModelWithZuordnung>(
      `/rest/v1/vermoegensberater/${vbNummer}/zuordnungen/assistenten/${uuid}`
      , 'PUT', data);
  }

  async cancelContract(vbNummer: string, uuid: string, kuendigung: AssistentKuendigung) {
    const {file, ...data} = kuendigung;
    const formData = new FormData();
    if (file) formData.append('pdf', file);
    formData.append('kuendigung', new Blob([JSON.stringify(data)], {type: 'application/json'}));
    await this.restClient.multiPart(
      `/rest/v1/vermoegensberater/${vbNummer}/zuordnungen/assistenten/${uuid}/kuendigungen`
      , 'POST', formData);

  }

  async remove(vbNummer: string, uuid: string) {
    await this.restClient.json(
      `/rest/v1/vermoegensberater/${vbNummer}/zuordnungen/assistenten/${uuid}`
      , 'DELETE');
  }

  async deleteRevokeCancellation(vbNummer: string, uuid: string) {
    // v1/vermoegensberater/{vbNummer}/zuordnungen/assistenten/{assistentUuid}/kuendigungen" mit DELETE
    await this.restClient.json(
      `/rest/v1/vermoegensberater/${vbNummer}/zuordnungen/assistenten/${uuid}/kuendigungen`
      , 'DELETE');
  }

  async confirm(vbNummer: string, uuid: string, pdf?: File) {
    const formData = new FormData();
    if (pdf) formData.append('pdf', pdf);
    return await this.restClient.multiPart(`/rest/v1/vermoegensberater/${vbNummer}/zuordnungen/assistenten/${uuid}/bestaetigen`, 'POST', formData);
  }

  async bestaetigtAm(data: BestaetigtAmModel) {
    const bestaetigtAm = DateUtils.toServerDateWithTime(data.date, data.time);
    return await this.restClient.json(`/rest/v1/vermoegensberater/${data.vbNummer}/zuordnungen/assistenten/by-asssistenten-nummer/${data.assistentNummer}/bestaetigung`, 'PUT', {
      bestaetigtAm,
      resetErinnerung: data.resetErinnerung
    });
  }
}
