import {RestClient} from './util/rest-client';

export class AddressValidatorService {
  constructor(private restClient: RestClient) {
  }

  async validate(address: AddressData): Promise<AddressValidation> {
    try {
      const data = {
        strasse: '',
        hausnummer: '',
        postleitzahl: '',
        ort: '',
        ...address,
      };
      const res = await this.restClient.json<any>(
        '/rest/v1/adressen/validieren',
        'POST',
        data
      );
      if (!res) {
        return null;
      }
      return res;
    } catch (error) {
      return null;
    }

  }

  async getOrt(address: AddressData): Promise<string> {
    const res = await this.validate(address);
    if (res) {
      switch(res.type) {
        case 'ValidierteAdresse':
          return res.korrigiert?.ort || null;
        case 'OrtAuswahl':
          const orte = res.orte?.length > 0 ? res.orte : null;
          if (orte) {
            return orte[0];
          }
          return null;
      }
    }
    return null;
  }

}

export interface AddressData {
  strasse?: string;
  hausnummer?: string;
  postleitzahl?: string;
  ort?: string;
}

type AddressValidation = ValidierteAdresse | OrtAuswahl | StrassenAuswahl |
  StrasseNichtGesetzt | WederOrtNochPostleitzahlGesetzt |
  OrtUnbekannt | PostleitzahlUnbekannt | StrasseUnbekannt | HausnummerUnbekannt | AdresseUnbekannt ;

export interface ValidationResult {
  type: string;
  original: AddressData;
}

export interface ValidierteAdresse extends ValidationResult {
  type: 'ValidierteAdresse';
  korrigiert: AddressData;
}

export interface OrtAuswahl extends ValidationResult {
  type: 'OrtAuswahl';
  orte: string[];
}

export interface StrassenAuswahl extends ValidationResult {
  type: 'StrassenAuswahl';
  strassen: {
    strasse: string;
    ort: string;
  }[];
}

export interface StrasseNichtGesetzt extends ValidationResult {
  type: 'StrasseNichtGesetzt';
}

export interface WederOrtNochPostleitzahlGesetzt extends ValidationResult {
  type: 'WederOrtNochPostleitzahlGesetzt';
}

export interface OrtUnbekannt extends ValidationResult {
  type: 'OrtUnbekannt';
}

export interface PostleitzahlUnbekannt extends ValidationResult {
  type: 'PostleitzahlUnbekannt';
}

export interface StrasseUnbekannt extends ValidationResult {
  type: 'StrasseUnbekannt';
}

export interface HausnummerUnbekannt extends ValidationResult {
  type: 'HausnummerUnbekannt';
}

export interface AdresseUnbekannt extends ValidationResult {
  type: 'AdresseUnbekannt';
}
