export class DateUtils {

  static formatToServerDate(date: Date): string {
    return date.toISOString().slice(0, 'yyyy-mm-dd'.length);
  }

  static extractDateTimeFromTimestamp(timestamp: string): {day: string; time: string;} {

    const day = timestamp.substr(8, 2);
    const month = timestamp.substr(5, 2);
    const year = timestamp.substr(0, 4);

    const hour = timestamp.substr(11, 2);
    const minutes = timestamp.substr(14, 2);
    return {
      day: `${day}.${month}.${year}`,
      time: `${hour}:${minutes}`,
    };
  }

  static toServerDateWithTime(date: string, time: string) {
    const hour = time.substr(0, 2);
    const minutes = time.substr(3, 2);

    return `${DateUtils.formatToServerDate(new Date(date))}T${hour}:${minutes}:00.000Z`;
  }

  static dateToServerDate(date: Date) {
    const day = this.withLeadingZero(date.getDate());
    const month = this.withLeadingZero(date.getMonth() + 1);
    const year = date.getFullYear();

    return `${year}-${month}-${day}T00:00:00.000Z`;
  }

  static dateToServerDateWithTime(date: Date) {
    const day = this.withLeadingZero(date.getDate());
    const month = this.withLeadingZero(date.getMonth() + 1);
    const year = date.getFullYear();

    const hours = this.withLeadingZero(date.getHours());
    const minutes = this.withLeadingZero(date.getMinutes());
    const seconds = this.withLeadingZero(date.getSeconds());

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
  }

  static dateToYYYYMMDD(date: Date) {
    const day = this.withLeadingZero(date.getDate());
    const month = this.withLeadingZero(date.getMonth() + 1);
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  }

  static toHumanReadableDate(dateString: string) {
    if (!dateString) {
      return 'Unbekannt';
    }
    const date = new Date(dateString);

    return this.showDate(date);
  }

  static dateYearsAgo(years: number): Date {
    const date = new Date();
    date.setFullYear(date.getFullYear() - years);
    return date;
  }

  private static withLeadingZero(n: number) {
    if (n < 10) {
      return `0${n}`;
    }
    return `${n}`;
  }

  static today() {
    const date = new Date();
    date.setHours(0,0,0,0);
    return date;
  }

  static dateInFuture(days: number): Date {
    const date = new Date();
    date.setDate(date.getDate() + days);
    date.setHours(0,0,0,0);
    return date;
  }

  static dateMonthInFuture(months: number, excludeToday = false): Date {
    const date = new Date();
    date.setMonth(date.getMonth() + months);
    if (excludeToday) {
      date.setDate(date.getDate() - 1);
    }
    return date;
  }

  static showDate(date: Date) {
    const day = this.withLeadingZero(date.getDate());
    const month = this.withLeadingZero(date.getMonth() + 1);
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  }

  static verlaengerunsDatum() {
    return DateUtils.showDate(DateUtils.dateInFuture(180));
  }

  static dateLowerThan(date1: string, date2: string): string {
    if (!date1 || !date2) {
      return 'Datum notwendig';
    }
    if (new Date(date1).getTime() > new Date(date2).getTime()) {
      return 'Das Datum muss höher sein als "Datum von"';
    }
    return null;
  }
}
